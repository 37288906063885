// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-slideshows-json-category-js": () => import("./../../../src/pages/categories/{SlideshowsJson.category}.js" /* webpackChunkName: "component---src-pages-categories-slideshows-json-category-js" */),
  "component---src-pages-end-of-gallery-js": () => import("./../../../src/pages/end-of-gallery.js" /* webpackChunkName: "component---src-pages-end-of-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-slideshows-json-slug-js": () => import("./../../../src/pages/{SlideshowsJson.slug}.js" /* webpackChunkName: "component---src-pages-slideshows-json-slug-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

